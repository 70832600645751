import { HiOutlineMail } from "react-icons/hi";
import { FiExternalLink } from "react-icons/fi";
import { FaWhatsapp, FaYoutube } from "react-icons/fa";

const data = [
  {
    id: 1,
    icon: <HiOutlineMail />,
    link: "mailto:grayspace.co.ke@proton.me.",
  },
  {
    id: 2,
    icon: <FiExternalLink />,
    link: "https://grayspace-ecommerce.herokuapp.com/",
  },
  { 
    id: 3, 
    icon: <FaWhatsapp />, 
    link: "https://wa.me/+254797450618" 
  },
  {
    id: 4,
    icon: <FaYoutube />,
    link: "https://www.youtube.com/channel/UCS3383FiVwJ0Lg_6KaH9b5A?sub_confirmation=1",
  }
];

// alternative WhatsApp link
// https://wa.me/yournumber
// https://api.whatsapp.com/send/?phone=%2B233557097546

export default data;
